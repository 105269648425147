<template>
  <div>
    <a-form layout="inline" @keyup.enter.native="searchQuery" style="margin-bottom: 30px">
      <a-row>
        <a-col :span="8">
          <a-form-item label="创建时间" :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }">
            <a-range-picker v-model="queryParam.createTime" style="width: 230px" />
          </a-form-item>
        </a-col>
        <a-col v-if="!isMerchant" :span="8">
          <a-form :form="form">
            <a-form-item label="商户" :labelCol="{ span: 9 }" :wrapperCol="{ span: 15 }">
              <a-select
                ref="select"
                style="width: 120px"
                @change="onUserNameChange"
                v-decorator="['userId']"
              >
                <a-select-option v-for="item in merchants" :value="item.userId" :key="item.userNo">
                  {{ item.userName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
            <a-button type="primary" style="margin-left: 10px" @click="() => {queryParam.pageNum = 1; searchQuery()}"
                      icon="search">查询</a-button>
            <a-button type="primary" style="margin-left: 10px" @click="reset">重置</a-button>
          </span>
        </a-col>
      </a-row>
    </a-form>
    <a-table
      ref="table"
      rowKey="id"
      size="middle"
      :columns="columns"
      :dataSource="dataSource"
      :loading="loading"
      :scroll="{ x: 1000 }"
      :pagination="false"
      bordered
    >
    </a-table>
    <div style="margin-top: 15px; text-align: right">
      <v-pagination
        v-if="total"
        :page-num="queryParam.pageNum"
        :page-size="queryParam.pageSize"
        :total="total"
        @change="handleTableChange"
      />
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import { getUserBalancePage } from '@/api/account'
import { getMerchantPage } from '@/api/pay'

export default {
  name: 'PayOutBankOrderList',
  data() {
    return {
      queryParam: {
        userId: '', //商户id
        beginTime: '', //开始时间
        endTime: '', //结束时间
        createTime: null,
        pageNum: 1,
        pageSize: 10
      },
      form: this.$form.createForm(this),
      isMerchant: sessionStorage.getItem('roleCodes')
        ? sessionStorage.getItem('roleCodes').includes('merchant')
        : false,
      merchants: [],
      dataSource: [],
      channels: [],
      loading: false,
      total: 0,
      columns: [
        {
          title: '日期',
          align: 'left',
          width: '100px',
          dataIndex: 'date'
        },
        {
          title: '商户名称',
          align: 'left',
          width: '100px',
          dataIndex: 'userName'
        },
        {
          title: '商户编号',
          align: 'left',
          width: '100px',
          dataIndex: 'userNo'
        },
        {
          title: '可用金额',
          align: 'left',
          width: '150px',
          dataIndex: 'payOutBalance'
        },
        {
          title: '在途金额',
          align: 'left',
          width: '150px',
          dataIndex: 'payInBalance'
        }
      ],
      confirmLoading: false,
      modalvisible: false
    }
  },
  created() {
    this.queryParam.createTime = [moment().subtract(7, 'days'), moment().add(0, 'days')]

    getMerchantPage({ pageNum: 1, pageSize: 999 }).then((res) => {
      this.merchants = res.data.list
    })
  },
  methods: {
    onUserNameChange(userId) {
      this.queryParam.userId = userId
    },
    reset() {
      const ignoreFields = ['pageNum', 'pageSize']
      for (let key in this.queryParam) {
        if (ignoreFields.indexOf(key) === -1) {
          this.queryParam[key] = ''
        }
      }
      this.queryParam.pageNum = 1
      this.queryParam.pageSize = 10
      this.form.resetFields(['userId']);
      this.queryParam.createTime = null
    },
    handleTableChange(pageNum, pageSize) {
      this.queryParam.pageNum = pageNum
      this.queryParam.pageSize = pageSize
      this.searchQuery()
    },
    searchQuery() {
      this.loading = true
      const params = { ...this.queryParam }
      const { createTime } = params

      if (createTime) {
        params.startDate = `${moment(createTime[0]).format('YYYY-MM-DD')}`
        params.endDate = `${moment(createTime[1]).format('YYYY-MM-DD')}`
        delete params.createTime
      }

      getUserBalancePage(params).then((res) => {
        this.dataSource = res.data.list
        this.total = res.data.total
        this.loading = false
      })
    }
  }
}
</script>
<style scoped lang="less">
.appContainer ::v-deep {
  .ant-form-item {
    margin-bottom: 14px;
  }
}

/deep/ .ant-form-item {
  margin-bottom: 14px;
}

.modalContent {
  margin-top: 30px;

  .Item {
    width: 100%;
    display: flex;
    // justify-content: space-between;
    margin-bottom: 16px;

    .left {
      margin-right: 100px;
      width: 80px;
      text-align: right;
    }

    .right {
      text-align: left;
    }
  }
}

.modalContent2 {
  display: flex;

  .c1 {
    .Item {
      .left {
        width: 100px;
        margin-right: 80px;
        text-align: left;
      }

      .right {
        margin-right: 100px;
      }
    }
  }

  .c2 {
    .Item {
      .left {
        text-align: left;
        width: 180px;
        margin-right: 80px;
      }

      .right {
        text-align: left;
      }
    }
  }
}
</style>
