import { axios } from '@/utils/request'

// 账户列表
export function getaccountlist(data) {
  return axios({
    url: '/account/list',
    method: 'post',
    data,
  })
}
// 添加账户
export function getaccountadd(data) {
  return axios({
    url: '/account/add',
    method: 'post',
    data,
  })
}

// 改变账户状态
export function getaccountstatus(data) {
  return axios({
    url: '/account/changeStatus?' + 'accountId=' + data.accountId + '&' + 'status=' + data.status,
    method: 'post',
  })
}

// 查询账户流水
export function getaccountflow(data) {
  return axios({
    url: '/account/flowList',
    method: 'post',
    data,
  })
}

// 账户提现
export function getaccountwithdraw(data) {
  return axios({
    url: '/account/withdraw',
    method: 'post',
    data,
  })
}

// 账户充值
export function getaccounttopUp(data) {
  return axios({
    url: '/account/charge',
    method: 'post',
    data,
  })
}

// 根据accoundId查询Account信息
export function getAccountInfo(data = {}) {
  return axios({
    url: `/account/getAccountInfoByUserId`,
    method: 'post',
    type: 'form',
    data,
  })
}

export function getUserBalancePage(parameter = {}) {
  return axios({
    url: '/account/getDailyBalancePage',
    method: 'post',
    data: parameter,
  })
}