<template>
  <a-spin :spinning="loading">
    <div class="section-box">
      <div class="section-box-header">
        <span>{{ title }}</span>
      </div>
      <div class="section-box-body">
        <div class="content">
          <slot></slot>
        </div>
      </div>
    </div>
  </a-spin>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: undefined,
    },
  },
}
</script>
<style lang="less" scoped>
.section-box {
  position: relative;
  height: 100%;
  margin-bottom: 20px;
  border: 1px solid #e5e5e5;
  background: #fff;

  .section-box-header {
    padding: 0 14px;
    height: 42px;
    line-height: 42px;
    background: #fafafc;
    border-bottom: 1px solid #e5e5e5;
    color: #3b3b3b;
    box-sizing: border-box;
  }

  .content {
    padding: 25px 25px;
    width: 100%;
    margin: 0 auto;
  }
}
</style>
